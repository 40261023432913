<template>
  <b-overlay :show="isLoading">
    <b-row>
      <b-col>
        <h1>
          Features
        </h1>
      </b-col>
      <b-col cols="auto">
        <!-- Button -->
        <b-button variant="primary" @click="doSave">
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-card class="mt-3">
      <b-form-group :label="$t('features.authType')">
        <b-form-radio-group
          v-model="features.authentication.type"
          :options="authTypes"
          button-variant="outline-primary"
          name="radio-btn-outline"
          buttons
        ></b-form-radio-group>
      </b-form-group>
      <b-form-group v-if="requiresGlobalAccessCode">
        <b-form-input v-model="globalAccessCode.value"> </b-form-input>
      </b-form-group>
      <b-form-group
        v-for="item of featureKeys"
        :key="item"
        :label="$t(`features.${item}`)"
        label-cols-lg="2"
      >
        <b-form-checkbox v-model="features[item]" switch></b-form-checkbox>
      </b-form-group>
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  data() {
    return {
      authTypes: [
        { text: "Basic", value: "basic" },
        { text: "Access code", value: "code" },
        { text: "Only access code", value: "only-code" },
        { text: "Global code", value: "global-code" },
        { text: "None", value: "none" },
      ],
      featureKeys: [
        "comingSoon",
        "backgroundImage",
        "languagePicker",
        "landingPage",
        "registerForm",
        "paymentRequired",
        "scheduler",
        "sponsors",
        "expandedLogin",
      ],
      features: this.$store.getters["Features/all"],
      globalAccessCode: this.$store.getters["GlobalAccessCode/code"],
      isLoading: false,
    };
  },
  computed: {
    requiresGlobalAccessCode() {
      return this.features.authentication.type === "global-code";
    },
  },
  async created() {
    await this.doFetch();
  },
  methods: {
    async doSave() {
      this.isLoading = true;
      await this.$store.dispatch("Features/update", this.features);
      await this.$store.dispatch(
        "GlobalAccessCode/update",
        this.globalAccessCode
      );
      await this.$store.dispatch("Features/fetchAll");
      this.isLoading = false;
    },
    async doFetch() {
      this.isLoading = true;
      await this.$store.dispatch("Features/fetchAll");
      await this.$store.dispatch("GlobalAccessCode/fetch");
      this.isLoading = false;
    },
  },
};
</script>
<style lang="scss"></style>
